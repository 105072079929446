
// Fonts
@import url(https://fonts.googleapis.com/css?family=Raleway:300,400,600);

// Variables
@import "variables";

// Bootstrap
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";
@import "node_modules/dropzone/src/basic";
@import "node_modules/dropzone/src/dropzone";

.users-table{
	font-size: 12px;
}

.panel-heading{
  font-weight: bold;
}

.dz-success-mark svg {
  background-color: #2196F3;
  border-radius: 100%;
}

.dz-error-mark svg {
  background-color: #c34242;
  border-radius: 100%;
}